<template>
  <div class="container">
    <div class="transfer-courses mt-4">
      <h4 class="text-center">Перенос курсов (языковой центр)</h4>




      <DataTable :value="transferCourses_form.currentSemesterCourses" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10, 20, 50, 100, 200]"
                 filterDisplay="menu"
                 :globalFilterFields="['selectable_group_name', 'education_discipline_name']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                        <i class="pi pi-search"/>
                        <InputText v-model="filters['global'].value" placeholder="Поиск"/>
                    </span>
          </div>
        </template>
        <template #empty>
          Курс не найден
        </template>
        <template #loading>
          Идет поиск курсов. Пожалуйста подождите.
        </template>

        <Column field="education_discipline_name" header="Название дисциплины"></Column>
        <Column field="selectable_group_name" header="Название группы"></Column>
        <Column field="study_course" header="Курс"></Column>
        <Column field="semester" header="Семестр"></Column>
        <Column header="Преподаватель">
          <template #body="{data}">
            {{data.pps_data_lastname}} {{data.pps_data_firstname}}
          </template>
        </Column>

        <Column header="Перенести">
          <template #body="{data}">
            <div v-if="+data.next_semester_course_id">
              <Button :label="`${data.new_course_name}`" class="p-button-outlined" icon="pi pi-users"
                      @click="getStudents(data.new_course_id, data.new_course_name)"/>
            </div>
            <div v-else>
              <Button icon="pi pi-forward" @click="openTransferCoursesDialog(data)"/>
            </div>
          </template>
        </Column>


      </DataTable>


      <Dialog v-model:visible="transferCoursesDisplay" :style="{width: '50vw'}" :closable="false">
        <template #header>
          <h5>Перенести курс: {{currentSemesterCourseName}}, преподаватель: {{currentSemesterTeacherName}} </h5>
        </template>

        <div class="col-md-12 mt-4">


          <DataTable :value="transferCourses_form.nextSemesterCourses"
                     stripedRows responsiveLayout="scroll">

            <Column field="education_discipline_name" header="Название дисциплины"></Column>
            <Column field="semester" header="Семестр"></Column>

            <Column header="Выбрать">
              <template #body="{data}">
                <input class="form-check-input" name="chose_discipline" type="radio" @input="chooseDiscipline(data)">
              </template>
            </Column>


          </DataTable>
          <div class="mt-4 mb-4" v-if="chooseDisciplineStatus === true">
            <Button label="Поменять преподавателя" icon="pi pi-user" class="p-button-secondary"
                    @click="selectEditTeacher"/>
          </div>

          <div class="mt-4 mb-4" v-if="editTeacherStatus === true">
            <select class="form-control form-select"
                    @input="changeTeacher($event)">
              <option
                  v-for="(item, index) in [{id: 0, lastname: 'Выберите преподавателя'}, ...transferCourses_form.teachers]"
                  :value="item.id"
                  :key="index">{{ item.lastname }} {{ item.firstname }} {{ item.middlename }}
              </option>
            </select>
          </div>


        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeTransferCoursesDialog"/>
          <Button label="Добавить" icon="pi pi-check" @click="postTransferCourses"/>
        </template>
      </Dialog>

      <Dialog :header="`${newCourseName} - список студентов`" v-model:visible="displayStudents" :style="{width: '50vw'}">
        <div>
          <div class="my-2" v-for="(student, studentIndex) in transferCourses_form.students" :key="studentIndex">
            {{studentIndex+1}}. <b>{{student.barcode}}</b> {{student.last_name}} {{student.first_name}} {{student.middle_name}}
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" autofocus @click="closeStudentList"/>
        </template>
      </Dialog>


    </div>
  </div>


</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "TransferCourses",


  data() {
    return {
      transferCoursesDisplay: false,
      currentSemesterCourseName: '',
      currentSemesterTeacherName: null,
      chooseDisciplineStatus: false,
      editTeacherStatus: false,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      displayStudents: false,
      newCourseName: ''
    }
  },
  computed: {
    ...mapState('transferCourses', ['transferCourses_form']),
    //...mapState('selectableGroups', ['selectableGroups_form']),

  },

  methods: {
    ...mapActions('transferCourses', ['GET_CURRENT_SEMESTER_COURSES','GET_NEXT_SEMESTER_COURSES',
      'POST_NEXT_SEMESTER_LANGUAGE_COURSE', 'GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM', 'GET_STUDENTS_BY_COURSE_ID']),
    //...mapActions('selectableGroups', ['GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM']),
    ...mapMutations('transferCourses', ['SET_NEW_COURSE_DATA', 'ADD_NEW_COURSE_DATA', 'CLEAR_TRANSFER_COURSE_DATA', 'SET_UPDATE_LECTURE_PPS_ID',
    'SET_STUDENTS']),
    async openTransferCoursesDialog(data) {
      await this.CLEAR_TRANSFER_COURSE_DATA()
      this.transferCoursesDisplay = true
      console.log(data)
      this.currentSemesterCourseName = data.education_discipline_name
      this.currentSemesterTeacherName = data.pps_data_lastname + ' ' + data.pps_data_firstname

      await this.SET_NEW_COURSE_DATA(data)
      await this.GET_NEXT_SEMESTER_COURSES({education_program_id : data.education_program_id, semester: data.semester})
    },
    selectEditTeacher(){
      this.editTeacherStatus = true
    },
    changeTeacher(e, val = 'value') {
      const value = e.target[val]
      this.SET_UPDATE_LECTURE_PPS_ID(value)
    },
    closeTransferCoursesDialog() {
       this.transferCoursesDisplay = false
       this.chooseDisciplineStatus = false
       this.editTeacherStatus = false
    },
    async postTransferCourses(){
      this.transferCoursesDisplay = false
      this.chooseDisciplineStatus = false
      this.editTeacherStatus = false
      const res = await this.POST_NEXT_SEMESTER_LANGUAGE_COURSE()
      if (res) {
        this.$message({text: 'Курс успешно создан'})
        this.GET_CURRENT_SEMESTER_COURSES()
      }
    },
    async chooseDiscipline(data){
      await this.ADD_NEW_COURSE_DATA(data)
      this.chooseDisciplineStatus = true
      console.log(data.formation_education_program_id, 'formation_education_program_id')
      await this.GET_TEACHERS_BY_FORMATION_EDUCATION_PROGRAM(data.formation_education_program_id)
    },
    async getStudents(course_id, course_name) {
      this.newCourseName = course_name
      await this.GET_STUDENTS_BY_COURSE_ID(course_id)
      this.displayStudents = true
    },
    closeStudentList(){
      this.newCourseName = ''
      this.SET_STUDENTS([])
      this.displayStudents = false
    }
  },
  async mounted() {
    await this.GET_CURRENT_SEMESTER_COURSES()
  }
}
</script>

<style scoped>

</style>